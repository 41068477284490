import React, { useRef, useState } from "react"
import { Form, Button, Card, Alert, Image, Container, Collapse } from "react-bootstrap"
import { Link, useHistory } from "react-router-dom"
import { app, database, functions } from "../../firebase"
import { useAuth } from "../../contexts/UserContext"

export default function Create() {
  const emailRef = useRef()
  const passwordRef = useRef()
  const { signup, createPage } = useAuth()

  const pageRef = useRef()
  const [error, setError] = useState("")
  const [openSignup, setOpen] = useState(false);

  const [loading, setLoading] = useState(false)
  const history = useHistory()


  const toggleSignup = function(){
    if(openSignup) {
      document.getElementById("page-name-input").classList.remove("is-valid")
      document.getElementById("page-name-input").classList.add("is-invalid")
      document.getElementById("page-name-button").classList.remove("disabled")
    } else {
      document.getElementById("page-name-input").classList.remove("is-invalid")
      document.getElementById("page-name-input").classList.add("is-valid")
      document.getElementById("page-name-button").classList.add("disabled")
    }
  }

  async function checkPage(e) {
    e.preventDefault()
    setLoading(true)

    var pageResult = null
    database.pages
    .where("name", "==", pageRef.current.value)
    .get()
    .then(doc => {
      doc.forEach((doc) => {
            if (doc.exists){
              setError("Page already taken")
              setOpen(false)
              toggleSignup()
              pageResult = doc.data()
            }
        })
        if(!pageResult){
          setError("")
          setOpen(true)
          toggleSignup()
        }
      })
    .catch((error) => {
      setError(error.message)
    })
    setLoading(false)
  }


  async function claimPage(e) {
    e.preventDefault()

    try {
      setError("")
      setLoading(true)
      await signup(emailRef.current.value, passwordRef.current.value).then((userCridential) => {
        createPage(pageRef.current.value, userCridential.user.uid).then(() => {
          history.push("/")
        }).catch((error) => {
          setError(error.message)
        })
      }).catch((error) => {
        setError(error.message)
      })
    } catch {
      setError("Failed to create an account")
    }

    setLoading(false)
  }

  return (
    <>
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ minHeight: "100vh" }}
    >
      <Card className="w-100" style={{ maxWidth: "400px" }}>
        <Card.Body>
          <Image className="mx-auto d-block p-3" src="uplift-duo-logo.svg" fluid />
          <h2 className="text-center mb-4">Claim your page</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={checkPage} id="claim-form">
            <Form.Group id="text">
              <Form.Label>Page Name</Form.Label>
              <Form.Control id="page-name-input" type="text" ref={pageRef} required />
            </Form.Group>
            <Button id="page-name-button" disabled={loading} className="w-100" type="submit">
              Claim
            </Button>
          </Form>

          <Collapse in={openSignup} className="pt-3">
            <Form onSubmit={claimPage} id="signup-form">
              <Form.Group id="email">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" ref={emailRef} required />
              </Form.Group>
              <Form.Group id="password">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" ref={passwordRef} required />
              </Form.Group>
              {/* <Form.Group id="password-confirm">
                <Form.Label>Password Confirmation</Form.Label>
                <Form.Control type="password" ref={passwordConfirmRef} required />
              </Form.Group> */}
              <Button disabled={loading} className="w-100" type="submit">
                Sign Up
              </Button>
            </Form>
            </Collapse>
        </Card.Body>
        <div className="w-100 text-center my-3">
          Already have a page? <Link to="/login">Log In</Link>
        </div>        
      </Card>
      </Container>      
    </>
  )
}
