import './uplift.scss'

import React from 'react'
import ReactDOM from 'react-dom'

import reportWebVitals from './reportWebVitals'

import App from "./components/App"

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  
} else {
  reportWebVitals(console.log);
}

ReactDOM.render(
  <React.StrictMode>
      <App />
  </React.StrictMode>,
  document.getElementById('root')
);



