import React, { useRef, useState, useEffect, useContext } from "react"
import { Col, Row, Alert, Image, Form, Button, Tabs, Tab, Collapse, Card } from "react-bootstrap"
import { createPopper } from '@popperjs/core'
// import { Pills, Tooltip } from "bootstrap"
import * as bootstrap from 'bootstrap'


import { Link } from "react-router-dom"
import { database, storage } from "../../firebase"
import { useAuth } from "../../contexts/UserContext"

import { useLocation } from "react-router-dom"
import { usePage } from "../../hooks/usePage"

import { RiAccountCircleFill } from "react-icons/ri"

export default function Home() {
  const { currentUser } = useAuth()
  const [messageList, setMessages] = useState([])
  const [loading, setLoading] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [error, setError] = useState("")
  const inputRef = useRef()

  const { state = {} } = useLocation()
  const { page, pageLinks, reorderPageLinks } = usePage(currentUser.uid, state.page, state.pageLinks)

  /* States for editing page text */
  const [description, setDescription] = useState("")
  const [pageName, setPageName] = useState("")

  /* States for creating new link */
  const [url, setUrl] = useState("")
  const [type, setType] = useState("button")
  const [linkName, setLinkName] = useState("")

  /* States for editing link */
  const [currentEditLink, setEditLink] = useState(false)
  const [urlEdit, setEditUrl] = useState("")
  const [typeEdit, setEditType] = useState("button")
  const [iconSrcEdit, setEditIconSrc] = useState("icons/link-24.svg");
  const [linkNameEdit, setEditLinkName] = useState("")
  const [linkOrderEdit, setEditLinkOrder] = useState("")
  const [draggedItem, setDraggedItem] = useState(null);

  /* States for profile picture upload */
  const [file, setFile] = useState(null);
  const [uploadUrl, setUploadUrl] = useState("icons/upload-16.svg");

  /* Init functions */
  if (window.screen.width >= 768) {
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl)
    })
    console.log("desktop init")
  } else {
    console.log("mobile init")
  }
  

  /* Edit page: binds page to preview text and styles */
  useEffect(() => {
    console.log("useEffect[page] Updated ")
    console.log(page)
    if(page){
      if(page.description){
        setDescription(page.description)
      }
      if(page.displayName){
        setPageName(page.displayName)
      } else if(page.name){
        setPageName("@"+page.name)
      }
      if(page.prof){
        setUploadUrl(page.prof)
      }
      if(page.layout){
        setEditLayout(page.layout)
      }
      if(page.theme){
        setEditTheme(page.theme)
      }
      if(page.primaryColor){
        setPrimaryColor(page.primaryColor)
      }
      if(page.primaryColorInverse){
        setPrimaryColorInverse(page.primaryColorInverse)
      }
      if(page.backgroundColor){
        setBackgroundColor(page.backgroundColor)
      }
      if(page.color){
        setColor(page.color)
      }            
    }
    // startMessage()
  }, [page])
  
  
  /* profile picture functions */
  useEffect(() => {
    console.log("useEffect[file] Updated ")
    console.log(file)
    if(file){
      handleUpload()
    }
  }, [file])


  function handleUploadChange(e) {
    e.preventDefault()
    if(e.target.files[0] === file) {
      console.log('file the same')
      console.log(e.target.files[0])
      console.log(file)

    } else {
      console.log('new file set')
      console.log(file)
      console.log(e.target.files[0])

      setFile(e.target.files[0])
    }
  }

  function handleUpload() {
    if (file === null) return
    setLoading(true)
    setUploading(true)
    
    const ref = storage.ref(`/images/${page.id}/${file.name}`)
    const uploadTask = ref.put(file)
    uploadTask.on("state_changed", console.log, console.error, () => {
      ref
        .getDownloadURL()
        .then((url) => {

          database.pages.doc(page.id).set({
            prof: url
          }, {merge: true}).then(function(){
            page.url = url
          })

          setFile(null)
          setUploadUrl(url)
          page.prof = url
          setLoading(false)
          setUploading(false)
        })
    })
  }


  
  /* Edit text functions */
  function updateDescription(e) {
    e.preventDefault()
    setLoading(true)

    database.pages.doc(page.id).set({
      description: description
    }, {merge: true}).then(function(){
      page.description = description
      setLoading(false)
    })
  }

  function updateDisplayName(e) {
    e.preventDefault()
    setLoading(true)

    database.pages.doc(page.id).set({
      displayName: pageName
    }, {merge: true}).then(function(){
      page.displayName = pageName
      setLoading(false)
    })
  }

  /* Edit Page Links */
  useEffect(() => {
    console.log("useEffect[pageLinks] Updated ")
    console.log(pageLinks)
    console.log(pageLinks.slice(-1)[0])
    if(!url) return
    let newLink = pageLinks.find(link => link.url === cleanUrlCheck(url))
    if(newLink && newLink.url === cleanUrlCheck(url)){
      console.log("opening new link")
      console.log(newLink)
      openEditLink(null, newLink)
      setUrl("")
      setLinkName("")
    } else {
      console.log("link URL not new")
      console.log(url)
      console.log(newLink) 
      console.log(pageLinks)
    }
  }, [pageLinks])

  useEffect(() => {
    handleManualOrderChange(currentEditLink, linkOrderEdit)
  }, [linkOrderEdit])

  function handleManualOrderChange(updatedLink, newOrder) {
    // Create a copy of the current pageLinks and sort them by the current order
    let reorderedLinks = [...pageLinks].sort((a, b) => a.order - b.order);
    // Adjust the newOrder to fit within the array bounds
    newOrder = Math.max(0, Math.min(newOrder, reorderedLinks.length - 1));
    // Call updatePageLinksOrder with the reordered links, the updated link, and the new order
    reorderPageLinks(reorderedLinks, updatedLink, newOrder);
  }


  /* Add link functions */
  function handleAddLinkByType(e){
    e.preventDefault()

    if (page === null) return
    if (!url) {
      setError("Please enter a link URL")
      console.log("Please enter a URL")
      return
    }
    let newAddLink = pageLinks.find(link => link.url === cleanUrlCheck(url))
    if (newAddLink) {
      setError("Page link already added")
      console.log("Page link already added")
      return
    }
    

    setError("");
    setLoading(true);

    setType(e.target.value);
    console.log(url);

    let cleanedUrl = cleanUrlCheck(url);
    // setUrl(cleanedUrl)
    let linkNameDefault = blankNameCheck(linkName,cleanedUrl);
    let newOrder = Math.max(0, ...pageLinks.map(link => parseInt(link.order, 10))) + 1;

    console.log("New Order = " + newOrder);
    let newIconSrc = "icons/link-24.svg";

    let suggestedIcon = allIcons.find(link => cleanedUrl.includes(link.name));
    if(suggestedIcon){
      newIconSrc = suggestedIcon.src
    }

    // console.log("Adding link with = init url: "+url+" => cleanedUrl(): "+cleanedUrl+", linkNameDefault: "+linkName+" => "+linkNameDefault+", type: "+type+", page: "+page.id+", newOrder: "+newOrder++ +", newIconSrc: "+newIconSrc)

    database.pages.doc(page.id).collection("links").add({
      url: cleanedUrl,
      type: e.target.value,
      name: linkNameDefault,
      src: newIconSrc,
      order: newOrder,
      userName: page.name,
      createdAt: database.getCurrentTimestamp(),
      userId: currentUser.uid
    }).then((result) => {
      console.log(result)
      let link = result
      console.log("Link created with id: "+ link.id)
    })

    setError("")
    setLoading(false)
  }

  function cleanUrlCheck(urlCheck){
    if (!urlCheck) return
    let cleanedUrl = ""
    if (urlCheck.indexOf("http://") === 0 || urlCheck.indexOf("https://") === 0) {
      cleanedUrl = urlCheck;
      console.log("The link has http or https.");
    } else {
      if(urlCheck.indexOf(".") === -1) {
        console.log("The link doesn't https or .anything");
        cleanedUrl = "https://"+urlCheck+".com"      
      } else {
        console.log("The link doesn't have http or https.");
        cleanedUrl = "https://"+urlCheck
      }
    }
    return cleanedUrl
  }

  function blankNameCheck(nameCheck, cleanedUrl){
    if(nameCheck === ""){
      return cleanedUrl.substring(
        cleanedUrl.lastIndexOf("/") + 1, 
        cleanedUrl.lastIndexOf(".")
      )
    } else {
      return nameCheck
    }
  }
  
  var allIcons = [
    {
      name: "link",
      src: "icons/link-24.svg",
      color: "#fff" 
    },
    {
      name: "calendar",
      src: "icons/calendar-24.svg",
      color: "#fff"
    },
    {
      name: "mail",
      src: "icons/mail-24.svg",
      color: "#fff"
    },
    {
      name: "share",
      src: "icons/share-24.svg",
      color: "#fff"
    },    
    {
      name: "instagram",
      src: "icons/instagram-fill.svg",
      color: "#fff" 
    },
    {
      name: "facebook",
      src: "icons/facebook-fill.svg",
      color: "#fff"
    },
    {
      name: "linkedin",
      src: "icons/linkedin-fill.svg",
      color: "#fff"
    },
    {
      name: "github",
      src: "icons/github-fill.svg",
      color: "#fff"
    },
    {
      name: "pinterest",
      src: "icons/pinterest-fill.svg",
      color: "#fff"
    },
    {
      name: "spotify",
      src: "icons/spotify-fill.svg",
      color: "#fff"
    },
    {
      name: "snapchat",
      src: "icons/snapchat-fill.svg",
      color: "#fff"
    },
    {
      name: "twitch",
      src: "icons/twitch-fill.svg",
      color: "#fff"
    },
    {
      name: "youtube",
      src: "icons/youtube-fill.svg",
      color: "#fff"
    },
    {
      name: "venmo",
      src: "icons/cib-venmo.svg",
      color: "#fff"
    },
    {
      name: "zoom",
      src: "icons/cib-zoom.svg",
      color: "#fff"
    },
    {
      name: "paypal",
      src: "icons/paypal-fill.svg",
      color: "#fff"
    },
    {
      name: "podcast",
      src: "icons/podcast-black.svg",
      color: "#fff"
    },
    {
      name: "slack",
      src: "icons/slack.svg",
      color: "#fff"
    },
    {
      name: "tik",
      src: "icons/tik-tok.svg",
      color: "#fff"
    },           
  ]


  /* Edit Page Link */
  useEffect(() => {
    console.log("useEffect[currentEditLink] Updated ")
    console.log(currentEditLink)

    setEditUrl((currentEditLink && currentEditLink.url) ? currentEditLink.url : "")
    setEditType((currentEditLink && currentEditLink.type) ? currentEditLink.type : "button")
    setEditLinkName((currentEditLink && currentEditLink.name) ? currentEditLink.name : "")
    setEditIconSrc((currentEditLink && currentEditLink.src) ? currentEditLink.src : "icons/link-24.svg")
    setEditLinkOrder((currentEditLink && currentEditLink.order) ? currentEditLink.order : 0)
  }, [currentEditLink])


  useEffect(() => {
    console.log("useEffect[urlEdit] Updated ")
    console.log(urlEdit)

  }, [urlEdit])
    
  function openUploadProf(e){
    if(e){
      e.preventDefault()
    }    
    if(!document.getElementById("edit-image").classList.contains("show")){
      document.getElementById("image-tab").click()
    }
    setFile(null)
  }

  function openEditText(e){
    if(e){
      e.preventDefault()
    }    
    if(!document.getElementById("edit-text").classList.contains("show")){
      document.getElementById("text-tab").click()
    }    
  }

  function openEditStyle(e){
    if(e){
      e.preventDefault()
    }
    if(!document.getElementById("edit-style").classList.contains("show")){
      document.getElementById("style-tab").click()
    }
  }

  function openEditLink(e, selectedCurrentEditLink){
    if(e){
      e.preventDefault()
    }
    // if (selectedCurrentEditLink && currentEditLink === selectedCurrentEditLink) {
    //   selectedCurrentEditLink = currentEditLink
    // }
    if(!document.getElementById("edit-link").classList.contains("show")){
      document.getElementById("link-tab").click()
    }

    setEditLink(selectedCurrentEditLink)
  }  

  function openAddLink(e,v){
    if(e){
      e.preventDefault()
    }    
    if(!document.getElementById("add").classList.contains("show")){
      document.getElementById("add-tab").click()
    }
  }

  function handleDragStart(e, item) {
    setDraggedItem(item);
    setEditLink(item)
  }
  
  function handleDragOver(e) {
    e.preventDefault(); // Necessary for allowing a drop
  }
  
  function handleDrop(e, targetItem) {
    e.preventDefault();
    const reorderedLinks = reorderLinks(pageLinks, draggedItem, targetItem);
    reorderPageLinks(reorderedLinks); // Update the global state with the new order
    setEditLinkOrder(targetItem.order);
    setDraggedItem(null); // Clear the dragged item
  }

  // Utility function to reorder links
  function reorderLinks(links, draggedItem, targetItem) {
    const updatedLinks = [...links];
    const draggedIndex = updatedLinks.findIndex(item => item.id === draggedItem.id);
    const targetIndex = updatedLinks.findIndex(item => item.id === targetItem.id);
    
    if (draggedIndex < 0 || targetIndex < 0) {
      return links; // Return the original array if something goes wrong
    }

    // Remove the dragged item from its original position
    updatedLinks.splice(draggedIndex, 1);
    // Insert the dragged item before the target item
    updatedLinks.splice(targetIndex, 0, draggedItem);

    // Update the order property based on the new index
    return updatedLinks.map((link, index) => ({ ...link, order: index }));
  }
  

  function handleEditTypeChange(e){
    // e.preventDefault()
    console.log(e)
    setEditType(e.target.value)
  }

  function clearIcons(){
    let activeToggleicons = document.querySelectorAll(".select-icon.active")
    console.log(activeToggleicons)
    if(activeToggleicons){
      [].forEach.call(activeToggleicons, function(el) {
        el.classList.remove("active");
      })
    }
    // return
  }
  function handleEditIconSrc(e){
    // e.preventDefault()
    console.log(e)
    clearIcons()
    e.target.parentElement.classList.add("active")
    setEditIconSrc(e.target.attributes.value.value)
  }
  
  function handleDeleteLink(e){
    e.preventDefault()
    setLoading(true)
    console.log(currentEditLink)
    currentEditLink.firedoc.ref.delete()
    .then(() => {
      console.log("Link deleted!");              
    })
    .catch((error) => {
        console.error("Error updating document: ", error);
    })
    setLoading(false)
    setEditLink(null)
  }

  function handleEditLink(e,v){
    e.preventDefault()
    if (pageLinks === null) return
    setLoading(true)
    let selectedCurrentEditLink = pageLinks.find(link => link.id === currentEditLink.id);
    let cleanedEditUrl = ""
    if(urlEdit){
      cleanedEditUrl = cleanUrlCheck(urlEdit)
      setEditUrl(cleanedEditUrl)
      console.log(cleanedEditUrl)
    } else {
      cleanedEditUrl = selectedCurrentEditLink.url
      console.log("kept same URL")
    }
    let checkedEditName = ""
    if(linkNameEdit === ""){
      checkedEditName = blankNameCheck(linkNameEdit,cleanedEditUrl)
      // setEditLinkName(checkedEditName)
      console.log(checkedEditName)
    } else {
      checkedEditName = linkNameEdit
      console.log(checkedEditName)
    }
    let updatedData = {
      name:  checkedEditName,
      src: iconSrcEdit || selectedCurrentEditLink.src,
      type: typeEdit || selectedCurrentEditLink.type,
      order: parseInt(linkOrderEdit, 10) || selectedCurrentEditLink.order,
      url: cleanedEditUrl,
    }
    selectedCurrentEditLink.firedoc.ref
    .update(updatedData)
    .then(() => {
        console.log("Link updated!")
    })
    .catch((error) => {
        console.error("Error updating document: ", error)
    })
    setLoading(false)
    // setEditLink(selectedCurrentEditLink)
  }


    /* States for styles */
    const [currentLayout, setEditLayout] = useState("undefined")
    const [currentTheme, setEditTheme] = useState("undefined")
    const [primaryColor, setPrimaryColor] = useState("#fac935")
    const [primaryColorInverse, setPrimaryColorInverse] = useState("#000000")
    const [backgroundColor, setBackgroundColor] = useState("#ffffff")
    const [color, setColor] = useState("#000000")

    // const [currentIconStyle, setEditIconStyle] = useState("default");
    // const [currentButtonStyle, setEditButtonStyle] = useState("default")

  function handleEditLayout(e){
    console.log(e.target.value)
    // e.preventDefault()
    if (page === null) return
    setLoading(true)
    page.firedoc.ref.update({layout: e.target.value})
    .then(() => {
      console.log("Layout set!")
    })
    .catch((error) => {
        console.error("Error updating layout: ", error)
    })
    setLoading(false)
    setEditLayout(e.target.value)
  }

  function handleThemeChange(e){
    // e.preventDefault()
    if (page === null) return
    setLoading(true)
    console.log(e)
    page.firedoc.ref.update({theme: e.target.value})
    .then(() => {
      console.log("Theme set!")
    })
    .catch((error) => {
        console.error("Error updating theme: ", error)
    })
    saveColors()
    setLoading(false)
    setEditTheme(e.target.value)
  }

  function invertColor(hex, bw) {
    if (hex.indexOf('#') === 0) {
      hex = hex.slice(1)
    }
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
    }
    if (hex.length !== 6) {
      throw new Error('Invalid HEX color.')
    }
    var r = parseInt(hex.slice(0, 2), 16),
      g = parseInt(hex.slice(2, 4), 16),
      b = parseInt(hex.slice(4, 6), 16)
    if (bw) {
      return (r * 0.299 + g * 0.587 + b * 0.114) > 186
        ? 'black'
        : 'white'
        // ? '#000000'
        // : '#FFFFFF'        
    }
    r = (255 - r).toString(16)
    g = (255 - g).toString(16)
    b = (255 - b).toString(16)
    return "#" + padZero(r) + padZero(g) + padZero(b)
  }
  function padZero(str, len) {
    len = len || 2;
    var zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
  }

  function handleEditPrimaryColorChange(e){
    e.preventDefault()
    console.log(e)
    setPrimaryColor(e.target.value)
    saveColors()
  }
  function handleEditBackgroundColorChange(e){
    e.preventDefault()
    console.log(e)
    setBackgroundColor(e.target.value)
    saveColors()
  }
function saveColors(){
  let invertColorResult = invertColor(backgroundColor, true)
  let invertPrimaryColorResult = invertColor(primaryColor, true)
  setColor(invertColorResult)
  setPrimaryColorInverse(invertPrimaryColorResult)    

  page.firedoc.ref.update({
    backgroundColor: backgroundColor,
    primaryColor: primaryColor,
    primaryColorInverse: primaryColorInverse,
    color: color,
  })
  .then(() => {
    console.log("Colors set!")
  })
  .catch((error) => {
    console.error("Error updating colors: ", error)
  }) 
}


  

  return (
    <div className="container-xxl">
      <Row id="home-container" className="">
      {/* <Col sm={8} md={6} id="main-container"> */}
      <div className="col-12 col-md-6 mt-3" id="options-container">
        {page ? 
            <div id="edit-page-container" className={`page-preview text-center ${color} ${currentTheme}`} style={{backgroundColor: backgroundColor}}>
              <div className="page-prof-container">
                <Image onClick={(e) =>openUploadProf()} className={`page-prof edit-page-item ${currentLayout} ${uploading ? "flash" : ""}`} src={uploadUrl} alt={page.name + " Page Pic"} />
              </div>
              <div className="page-items-container">
                <div className="page-display-name">
                  <h2 onClick={(e) =>openEditText()} className="highlight p-3">{pageName}</h2>
                </div>
                {description ? 
                  <div className="page-description">
                    <p onClick={(e) =>openEditText()} className="highlight p-3">{description}</p>
                  </div>
                  : <p onClick={(e) =>openEditText()} className="highlight text-muted text-decoration-underline p-3">add description</p>
                }
                <div className="page-buttons">
                {pageLinks && pageLinks.length > 0 ? 
                      pageLinks.map((link) =>{
                        if(link.type === "button")
                          return  <a onClick={(e)=>openEditLink(e, link, e.target.value)} href={link.url} className="page-button-container">
                                    <div id={link.id} key={link.id}
                                      style={{backgroundColor: currentTheme === "traditional" | currentTheme === "undefined" ? primaryColor : backgroundColor, border: currentTheme === "traditional" | currentTheme === "undefined" ? "3px solid transparent" : "3px solid " + primaryColor, color: currentTheme === "traditional" | currentTheme === "undefined" ? primaryColorInverse : color}} 
                                      className={`page-button edit-page-item ${currentEditLink === link ? "active" : ""}`}
                                      draggable
                                      onDragStart={(e) => handleDragStart(e, link)}
                                      onDragOver={handleDragOver}
                                      onDrop={(e) => handleDrop(e, link)}                                      
                                    >
                                      {link.name}
                                    </div>
                                  </a>
                        }
                      )
                  : <></>
                }
                </div>
                <div className="page-icons row justify-content-center">
                {pageLinks && pageLinks.length > 0 ? 
                      pageLinks.map((link) =>{
                        if(link.type === "icon")
                          return  <a id={link.id} onClick={(e) =>openEditLink(e, link)} key={link.id} href={link.url} 
                                    className={`col col-sm-2 btn btn-link m-2 edit-page-item ${currentEditLink === link ? "active" : ""}`}
                                    draggable
                                    onDragStart={(e) => handleDragStart(e, link)}
                                    onDragOver={handleDragOver}
                                    onDrop={(e) => handleDrop(e, link)}                                    
                                  >
                                    <img className="page-icon" src={link.src} alt={link.name}></img>
                                  </a>
                        }
                      )
                  : <></>
                }
                </div>
                <div className="">
                    <button onClick={openAddLink} className="icon-btn add-btn">
                      <div className="add-icon"></div>
                      <div className="btn-txt">Add</div>
                    </button>
                </div>
              </div>
            </div>
          : <></>
        }
      </div>
      <div className="col-10 col-md-5 mt-3" id="options-container">
        <div id="main-options-container">
          <div className="tab-content" id="edit-content">
            <div className="tab-pane fade" id="edit-image" role="tabpanel" aria-labelledby="image-tab">
              <Card>
                <Card.Body>
                  <h5 className="card-title text-muted">Picture</h5>
                  <hr></hr>

                  <form onSubmit={handleUpload}>
                    <label className="form-label" for="prof-upload-input">Upload</label>
                    <input id="prof-upload-input" onChange={handleUploadChange} type="file" className={`form-control form-control-lg ${uploading ? "disabled" : ""}`} />
                  </form>
                  <hr></hr>

                  <Form.Group as={Row} controlId="layout-select">
                    <label className="form-label">Size</label>
                    <div id="layout-select" value={currentLayout} type="radio" name="options" onChange={handleEditLayout} className="input-group justify-content-between">
                      <input value="full" type="radio" className="btn-check" name="options" id="full-layout" autoComplete="off"></input>
                      <label className={`btn btn-outline-primary ${currentLayout === "full" ? "active" : ""}`} htmlFor="full-layout">
                        <img className="icon-toggle-button edit" width="30" height="30" src="icons/full-screen.svg" alt="full"></img>
                      </label>
                      <input value="square" alt="square" type="radio" className="btn-check" name="options" id="square-layout" autoComplete="off"></input>
                      <label className={`btn btn-outline-primary ${currentLayout === "square" ? "active" : ""}`} htmlFor="square-layout">
                        <img className="icon-toggle-button edit" width="30" height="30" src="icons/square-outline.svg"></img>
                      </label>
                      <input value="round" type="radio" className="btn-check" name="options" id="rounded-layout" autoComplete="off"></input>
                      <label className={`btn btn-outline-primary ${currentLayout === "round" ? "active" : ""}`} htmlFor="rounded-layout">
                        <img className="icon-toggle-button edit p-1" width="30" height="30" src="icons/square-with-round-corners.svg" alt="rounded"></img>
                      </label>
                      <input value="circle" type="radio" className="btn-check" name="options" id="circle-layout" autoComplete="off"></input>
                      <label className={`btn btn-outline-primary ${currentLayout === "circle" ? "active" : ""}`} htmlFor="circle-layout">
                        <img className="icon-toggle-button edit" width="30" height="30" src="icons/dot-24.svg" alt="circle"></img>
                      </label>
                      <input value="small-square" type="radio" className="btn-check" name="options" id="small-layout" autoComplete="off"></input>
                      <label className={`btn btn-outline-primary ${currentLayout === "small-square" ? "active" : ""}`} htmlFor="small-layout">
                        <img className="icon-toggle-button edit" width="30" height="30" src="icons/square-24.svg" alt="small"></img>
                      </label>
                    </div>
                  </Form.Group>
                </Card.Body>
              </Card>
            </div>
            <div className="tab-pane fade" id="edit-text" role="tabpanel" aria-labelledby="text-tab">
              <Card>
                <Card.Body>
                <h5 className="card-title text-muted">Text</h5>
                <hr></hr>

                  <Form onSubmit={updateDescription}>
                    <Form.Group>
                      <Form.Label>Description or Bio</Form.Label>
                      <Form.Control as="textarea" rows={3}
                        value={description}
                        onChange={e => setDescription(e.target.value)}
                        placeholder={page && page.description? page.description : "About you"}
                      />
                    </Form.Group>
                    <button className="btn btn-lg btn-primary w-100" type="submit">
                      Update
                    </button>
                  </Form>
                  <hr></hr>

                  <Form onSubmit={updateDisplayName}>
                    <Form.Group>
                      <Form.Label>Page Display Name</Form.Label>
                      <Form.Control
                        type="text"
                        value={pageName}
                        onChange={e => setPageName(e.target.value)}
                        placeholder={page && page.displayName ? page.displayName : "Your name"}                
                      />
                    </Form.Group>
                    <button className="btn btn-lg btn-primary w-100" type="submit">
                      Update
                    </button>
                  </Form>
                </Card.Body>
              </Card>
            </div>
            <div className="tab-pane fade" id="edit-style" role="tabpanel" aria-labelledby="style-tab">
              <Card>
                <Card.Body>
                  <h5 className="card-title text-muted">Style</h5>
                  <hr></hr>

                  <label className="form-label">Theme</label>
                  <div id="theme-select" value={currentTheme} type="radio" name="options" onChange={handleThemeChange} className={`d-grid ${color} `} style={{backgroundColor: backgroundColor}}>
                    <input value="undefined" type="radio" className="btn-check" name="options" id="undefined-theme" autoComplete="off"></input>
                    <label className="btn btn-undefined"  style={{backgroundColor: primaryColor, border: "3px solid " + primaryColor, color: primaryColorInverse}} htmlFor="undefined-theme">
                      Default
                    </label>
                    <input value="traditional" type="radio" className="btn-check" name="options" id="traditional-theme" autoComplete="off"></input>
                    <label className="btn btn-traditional" style={{backgroundColor: primaryColor, border: "3px solid " + primaryColor, color: primaryColorInverse}} htmlFor="traditional-theme">
                      Traditional
                    </label>
                    <input value="minimalist" type="radio" className="btn-check" name="options" id="minimalist-theme" autoComplete="off"></input>
                    <label className="btn btn-minimalist" style={{backgroundColor: backgroundColor, border: "3px solid " + primaryColor, color: color}}  htmlFor="minimalist-theme">
                      Minimalist
                    </label>
                    <input value="modern" type="radio" className="btn-check" name="options" id="modern-theme" autoComplete="off"></input>
                    <label className="btn btn-modern" style={{backgroundColor: backgroundColor, border: "3px solid " + primaryColor, color: color}}  htmlFor="modern-theme">
                      Modern
                    </label>
                    <input value="retro" type="radio" className="btn-check" name="options" id="retro-theme" autoComplete="off"></input>
                    <label className="btn btn-retro" style={{backgroundColor: backgroundColor, border: "3px solid " + primaryColor, color: color}}  htmlFor="retro-theme">
                      Retro
                    </label>
                    <input value="artistic" type="radio" className="btn-check" name="options" id="artistic-theme" autoComplete="off"></input>
                    <label className="btn btn-artistic" style={{backgroundColor: backgroundColor, border: "3px solid " + primaryColor, color: color}}  htmlFor="artistic-theme">
                      Artistic
                    </label>
                  </div>
                  <hr></hr>
                  <label htmlFor="color-picker-background" className="form-label">Background Color</label>
                  <input className="color-picker btn btn-lg btn-primary w-100" type="color" id="color-picker-background" onChange={handleEditBackgroundColorChange} value={backgroundColor}></input>
                  <hr></hr>
                  <label htmlFor="primary-picker-primary" className="form-label">Primary Color</label>
                  <input className="color-picker btn btn-lg btn-primary w-100" type="color" id="color-picker-primary" onChange={handleEditPrimaryColorChange} value={primaryColor}></input>
                </Card.Body>
              </Card>
            </div>
            <div className="tab-pane fade" id="edit-link" role="tabpanel" aria-labelledby="link-tab">
              <Card>
                <Card.Body>
                <h5 className="card-title text-muted">Edit Link</h5>
                <hr></hr>

                  {currentEditLink ?
                    <Form onSubmit={handleEditLink}>
                      <div id="link-url-form">
                        <div className="my-3 row">
                          <label for="link-url-select" className="form-label col-form-label col-md-2 col-sm-auto">
                          Link
                          </label>
                          <div className="col-md-10 col-sm">
                            <Form.Control required type="text"  placeholder="Link to go to" value={urlEdit} onChange={e => setEditUrl(e.target.value)}/>
                          </div>
                        </div>                          
                      </div>
                      <div id="link-type-form">
                        <div className="my-3 row">
                          <label for="link-type-select" className="form-label col-form-label col-md-2 col-sm-auto">
                            Type
                          </label>
                          <div className="col-md-10 col-sm">
                            <div id="link-type-select" type="button" name="options" className="input-group justify-content-between">
                              <button onClick={handleEditTypeChange} value="button" type="button" className={`btn btn-outline-primary flex-fill ${typeEdit === "button" ? "active" : ""}`}>Button</button>
                              <button onClick={handleEditTypeChange} value="icon" type="button" className={`btn btn-outline-primary flex-fill ${typeEdit === "icon" ? "active" : ""}`}>Icon</button>
                            </div>
                          </div>
                        </div>
                      </div>
                      {currentEditLink && typeEdit === "button" && (
                        <div id="link-title-form">
                          <div className="my-3 row">
                            <label for="link-title-form" className="form-label col-form-label col-md-2 col-sm-auto">
                              Title
                            </label>
                            <div className="col-md-10 col-sm">
                              <Form.Control type="text" placeholder="Name of the button" value={linkNameEdit} onChange={e => setEditLinkName(e.target.value)}/>
                            </div>
                          </div>                          
                        </div>
                      )}
                      {currentEditLink && typeEdit === "icon" && (
                        <div className="my-3 row">
                          <label for="icon-src-select" className="form-label col-form-label col-md-2 col-sm-auto">
                            Image
                          </label>
                            <div className="col-md-10 col-sm">
                            <div id="icon-src-select" type="radio" name="options" role="group" className="input-group">
                              {allIcons.map(icon => (
                                <div className="">
                                  <input value={icon.src} key={icon} alt={icon} type="radio" className="btn-check" name="options" autoComplete="off"></input>
                                  <label className={`select-icon icon-toggle-button edit ${iconSrcEdit && icon.src === iconSrcEdit ? "active" : ""} btn btn-outline-primary `} htmlFor={icon}>
                                    <img value={icon.src} key={icon} onClick={handleEditIconSrc} width="30" height="30" name="options" type="radio" autoComplete="off" src={icon.src} alt={icon}></img>
                                  </label>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}

                      <div id="link-order-form">
                        <div className="my-3 row">
                          <label for="link-order-form" className="form-label col-form-label col-md-2 col-sm-auto">
                            Order
                          </label>
                          <div className="col-md-10 col-sm">
                            <Form.Control type="number" placeholder="Order Number" value={linkOrderEdit} onChange={e => setEditLinkOrder(e.target.value)}/>
                          </div>
                        </div>                          
                      </div>                      
                      

                      <hr></hr>
                      <div className="row mt-3">
                        <div className="col-6 text-center">
                          <button className="icon-btn add-btn">  
                            <div onClick={handleDeleteLink}className="btn-txt">Remove</div>
                          </button>
                        </div>
                        <div className="col-6">
                          <button className="btn btn-primary btn-lg w-100" variant="primary" type="submit">
                            Update
                          </button>
                        </div>
                      </div>
                    </Form>
                    :
                    <div className="text-center">
                      <div>Click on a link to edit</div>
                      <hr></hr>
                      <p onClick={openAddLink}>Or add a new link</p>
                      <button onClick={openAddLink} className="icon-btn add-btn">
                        <div className="add-icon"></div>
                        <div className="btn-txt">Add</div>
                      </button>                      
                    </div>
                  }
                </Card.Body>
              </Card>
            </div>
            <div className="tab-pane fade" id="add" role="tabpanel" aria-labelledby="add-tab">
              <Card>
                <Card.Body>
                <h5 className="card-title text-muted">New Link</h5>
                <hr></hr>

                  <Form>
                    <Form.Group as={Row} controlId="link-url-form">
                      <Form.Label column sm="auto">
                        Link
                      </Form.Label>
                      <Col>
                        <Form.Control className="required" required type="text" placeholder="https://go.to.duo.page" value={url} onChange={e => setUrl(e.target.value)}/>
                        <div id="link-add-by-type-select" type="group" name="options" className="input-group justify-content-between">
                          <button className="btn btn-primary flex-fill" onClick={handleAddLinkByType} value="button">Add Button</button>
                          <button className="btn btn-primary flex-fill" onClick={handleAddLinkByType} value="icon">Add Icon</button>
                        </div>                        
                      </Col>
                    </Form.Group>
                  </Form>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
        <div id="messages">
          {messageList.map(message => (
              <Alert key={message.id} value={message.id} variant={message.variant}>
                {message.text}
              </Alert>
          ))}
          {error && <Alert variant="danger">{error}</Alert>}
        </div>
      </div>

      <div className="col-2 col-md-1 mt-3">
        <div className="nav flex-column nav-pills" id="edit-nav" role="tablist" aria-orientation="vertical">
          <button className="nav-link edit-link" id="image-tab" data-bs-toggle="pill" data-bs-target="#edit-image" type="button" role="tab" aria-controls="edit-image" aria-selected="false">
            <img className="" src="icons/image-24.svg" alt="profile image icon" width="30" height="30" title="Profile Picture" data-bs-toggle="tooltip" data-bs-placement="left"></img>
          </button>
          <button className="nav-link edit-link" id="text-tab" data-bs-toggle="pill" data-bs-target="#edit-text" type="button" role="tab" aria-controls="edit-text" aria-selected="false">
            <img className="" src="icons/pencil-24.svg" alt="text icon" width="30" height="30" title="Text" data-bs-toggle="tooltip" data-bs-placement="left"></img>
          </button>
          <button onClick={openEditStyle} className="nav-link edit-link" id="style-tab" data-bs-toggle="pill" data-bs-target="#edit-style" type="button" role="tab" aria-controls="edit-style" aria-selected="false">
            <img className="" src="icons/cil-color-palette.svg" alt="style icon" width="30" height="30" title="Style" data-bs-toggle="tooltip" data-bs-placement="left"></img>
          </button>
          <button onClick={openEditLink} className="nav-link edit-link" id="link-tab" data-bs-toggle="pill" data-bs-target="#edit-link" type="button" role="tab" aria-controls="edit-link" aria-selected="false">
            <img className="" src="icons/link-24.svg" alt="link icon" width="30" height="30" title="Edit Link" data-bs-toggle="tooltip" data-bs-placement="left"></img>
          </button>
          <button className="nav-link edit-link" id="add-tab" data-bs-toggle="pill" data-bs-target="#add" type="button" role="tab" aria-controls="add" aria-selected="false">
            <img className="" src="icons/plus-24.svg" alt="add icon" width="30" height="30" title="Add Link" data-bs-toggle="tooltip" data-bs-placement="left"></img>
          </button>
        </div>
      </div>
            
      </Row>

      <nav className="page-nav fixed-bottom">
        <ul className="nav d-flex justify-content-between">
          <li className="nav-item">
            <a className="nav-link active" aria-current="page" href="/">
              <img
                src="/uplift-duo-logo.svg"
                width="30"
                height="30"
                className={`${loading ? "loading" : ""}`} 
                alt="Uplift Duo Logo"
              />
            </a>
          </li>
          <li className="nav-item">
            <a id="account-button-container" as={Link} to="/user" href="/user" className="nav-link"><RiAccountCircleFill /></a>          
          </li>
        </ul> 
      </nav>
     
    </div>
  )
}
