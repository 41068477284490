import React, { useEffect, useState } from "react"
import { Row, Image, Container } from "react-bootstrap"
import { useParams, useLocation, Link } from "react-router-dom"
import { database } from "../../firebase"

export default function Page({ pageNameRequest }) {
  const { pageName } = useParams()
  const { state = {} } = useLocation()
  const [currentPage, setPage]= useState([])
  const [pageFound, setPageFound]= useState(true)
  const [currentLinks, setLinks]= useState([])
  const [currentIcons, setIcons]= useState([])
  const [loading, setLoading] = useState(true)

  var pageRequest;

  /* For URL request or direct page request call */ 
  if(!pageName){
    pageRequest = pageNameRequest
  } else {
    pageRequest = pageName
  }

  function getPage(){
    setLoading(true)
    database.pages
    .where("name", "==", pageRequest)
    .get()
    .then(existingPages => {
      const existingPage = existingPages.docs[0]
      console.log("getPage returned:")
      console.log(existingPage)
      if (existingPage) {
        let pageData = database.formatDoc(existingPage)
        console.log("page found:")
        console.log(pageData)
        setPage(pageData)
        setPageFound(true)
        setLoading(false)
      } else {
        setPageFound(false)
        setLoading(false)
        console.log("No page!")
      }
    })
    .catch((error) => {
      console.log(error.message)
      setLoading(false)
    })
  }

  function getLinks(){
    setLoading(true)
    let currentLinks = []
    let currentIcons = []
    database.store.collectionGroup('links')
    .where("userName", "==", pageRequest)
    .orderBy("order")
    .get()
    .then(docs => {
      docs.forEach(doc => {
        if (doc.exists){
          let fetchedLink = database.formatDoc(doc)
          if (fetchedLink.type === "button"){
            currentLinks.push(fetchedLink)
          }                         
          if (fetchedLink.type === "icon"){
            currentIcons.push(fetchedLink)          
          }
        } else {
          console.log("No links!")
        }
      })
      if(currentLinks.length > 0){
        console.log(currentLinks)
        console.log(currentLinks.sort(function (a, b) {
          return a.order - b.order;
        }))
      }
      setLinks(
        currentLinks.sort(function (a, b) {
          return a.order - b.order;
        })        
      )
      setIcons(
        currentIcons.sort(function (a, b) {
          return a.order - b.order;
        })
      )
      setLoading(false) 
    })
    .catch((error) => {
      console.log(error.message)
      setLoading(false)
    })
  }

  useEffect(() => {
    console.log("useEffect[pageName] called, geting page and links:")
    getPage()
    getLinks()

  }, [pageName])

  return (
    <div className={`page-container ${currentPage.theme}`} style={{backgroundColor: currentPage.backgroundColor}}>
      <div className="container-fluid">
          <div className="page-prof-container">
          {currentPage.prof
              ?
                <Image  className={`page-prof ${currentPage.layout}`} src={currentPage.prof} alt={currentPage.name + "Page Pic"} />
              :
              <></>
          }
          </div>
          <div className={`page-items-container ${currentPage.theme}`}>

                <div className={`page-display-name pb-3 ${currentPage.color}`}>
                  <h4>{currentPage.displayName ? currentPage.displayName : currentPage.name ? `@`+ currentPage.name : ""}</h4>
                </div>

              {currentPage.description
                ? 
                <div className={`page-description ${currentPage.color}`}>
                  <p className="p-3">{currentPage.description}</p>
                </div>
                :
                <></>
              }      

              {currentLinks && currentLinks.length > 0
                ? 
                  currentLinks.map((link) =>
                    <a key={link.id} className="page-button" href={link.url} 
                      style={{
                        backgroundColor: currentPage.primaryColor === undefined ? "#bdbdbd" : currentPage.theme === "traditional" | currentPage.theme === "undefined" ? currentPage.primaryColor : currentPage.backgroundColor, 
                        border: currentPage.theme === "traditional" | currentPage.theme === "undefined" ? "3px solid transparent" : "3px solid " + currentPage.primaryColor, 
                        color: currentPage.theme === "traditional" | currentPage.theme === "undefined" ? currentPage.primaryColorInverse : currentPage.color
                      }}
                    >{link.name}</a>
                  )
                :
                <></>
              }

              <div className={`page-icons row justify-content-center ${currentPage.color}`}>
              {currentIcons && currentIcons.length > 0
                ? 
                  currentIcons.map((link) => 
                    <a key={link.id} href={link.url} className="col btn btn-link m-2"><img className="page-icon" src={link.src} alt={link.name}></img></a>
                  )     
                :
                <></>
              }
              </div>              
      
              <nav className="page-nav navbar fixed-bottom">
                <a className={`page-logo ${loading ? "loading" : ""}`} href="/">
                  <img src="/uplift-duo-logo.svg" alt="Uplift Duo Logo"></img>
                </a>
              </nav>

            {!pageFound ?
              <div className="pt-3 m-3">
                <div className="card p-3 mx-auto my-3">
                  <p className="pt-2">Couldn't find the page you are looking for</p>
                  <a as={Link} to="/welcome" href="/welcome" className="m-3 btn btn-lg btn-primary">Get your page</a>
                  <div className="w-100 text-center my-3">
                    Already have a page? <Link as={Link} to="/login">Log In</Link>
                  </div>
                </div>
              </div>              
              :
              <></>
            }

          </div>
      </div>
        <div className="page-nav-holder"></div>
    </div>
  )
}
