import React from "react"
import Signup from "./authentication/Signup"
import { UserProvider } from "../contexts/UserContext"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Profile from "./authentication/Profile"
import Login from "./authentication/Login"
import PrivateRoute from "./authentication/PrivateRoute"
import ForgotPassword from "./authentication/ForgotPassword"
import UpdateProfile from "./authentication/UpdateProfile"
import Dashboard from "./files/Dashboard"
import Page from "./pages/Page"
import Welcome from "./pages/Welcome"
import Create from "./pages/Create"
import Home from "./pages/Home"


function App() {
  return (
      <>
          <Router>
            <UserProvider>
              <Switch>
                {/* User Pages */}
                <PrivateRoute exact path="/" component={Home} />
                <PrivateRoute path="/user" component={Profile} />
                <PrivateRoute path="/update-profile" component={UpdateProfile} />                

                {/* Files */}
                <PrivateRoute exact path="/files" component={Dashboard} />
                <PrivateRoute exact path="/files/folder/:folderId" component={Dashboard} />

                <Route exact path="/welcome" component={Welcome} />
                <Route exact path="/create" component={Create} />

                {/* Auth */}
                <Route path="/signup" component={Signup} />
                <Route path="/login" component={Login} />
                <Route path="/forgot-password" component={ForgotPassword} />

                {/* Page */}
                <Route exact path="/:pageName" component={Page} />
              </Switch>
            </UserProvider>
          </Router>
      </>
  )
}

export default App