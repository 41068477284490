import React, { useContext, useState, useEffect } from "react"
import { auth, database } from "../firebase"

const UserContext = React.createContext()

export function useAuth() {
  return useContext(UserContext)
}

export function UserProvider({ children }) {
  const [currentUser, setCurrentUser] = useState()
  const [loading, setLoading] = useState(true)

  function signup(email, password) {
    return auth.createUserWithEmailAndPassword(email, password)
  }

  // function createPage(pageName,useId) {
  //   return database.pages.doc(useId).set({
  //     name: pageName,
  //     description: "",
  //     prof: null
  //   })
  // }  

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password)
  }

  function logout() {
    return auth.signOut()
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email)
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email)
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password)
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user)
      setLoading(false)
    })

    return unsubscribe
  }, [])

  const value = {
    currentUser,
    login,
    signup,
    // createPage,
    logout,
    resetPassword,
    updateEmail,
    updatePassword
  }

  return (
    <UserContext.Provider value={value}>
      {!loading && children}
    </UserContext.Provider>
  )
}
