import firebase from "firebase/app"
import "firebase/auth"
import "firebase/analytics";
import "firebase/firestore"
import "firebase/storage"
import "firebase/functions"



const app = firebase.initializeApp({
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
})

export const firestore = app.firestore()
export const database = {
  store: firestore,
  pages: firestore.collection("pages"),
  links: firestore.collection("links"),  
  folders: firestore.collection("folders"),
  files: firestore.collection("files"),
  formatDoc: doc => {
    return { id: doc.id, firedoc: doc, ...doc.data() }
  },
  getCurrentTimestamp: firebase.firestore.FieldValue.serverTimestamp,
}
export const analytics = app.analytics();
export const functions = app.functions()
export const storage = app.storage()
export const auth = app.auth()
export default app

