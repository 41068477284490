import { useReducer, useEffect } from "react"
import { useAuth } from "../contexts/UserContext"
import { database, firestore } from "../firebase"

const ACTIONS = {
  SELECT_PAGE: "select-page",
  UPDATE_PAGE: "update-page",
  SET_PAGE_LINKS: "set-page-links",
  REORDER_PAGE_LINKS: "reorder-page-links",

}

export const ROOT_PAGE = { name: "", id: null }

function reducer(state, { type, payload }) {
  switch (type) {
    case ACTIONS.SELECT_PAGE:
      return {
        pageId: payload.pageId,
        page: payload.page,
        pageLinks: [],
      }
    case ACTIONS.UPDATE_PAGE:
      return {
        ...state,
        page: payload.page,
      }
    case ACTIONS.REORDER_PAGE_LINKS:
      return {
        ...state,
        pageLinks: payload.pageLinks,
      }      
    case ACTIONS.SET_PAGE_LINKS:
      return {
        ...state,
        pageLinks: payload.pageLinks,
      }
    default:
      return state
  }
}

export function usePage(pageId = null, page = null, pageLinks = null) {
  const [state, dispatch] = useReducer(reducer, {
    pageId,
    page,
    pageLinks: [],
  })
  const { currentUser } = useAuth()

  useEffect(() => {
    dispatch({ type: ACTIONS.SELECT_PAGE, payload: { pageId, page } })
  }, [pageId, page])

  useEffect(() => {
    if (pageId == null) {
      return dispatch({
        type: ACTIONS.UPDATE_PAGE,
        payload: { page: ROOT_PAGE },
      })
    }

    database.pages
      .doc(pageId)
      .get()
      .then(doc => {
        dispatch({
          type: ACTIONS.UPDATE_PAGE,
          payload: { page: database.formatDoc(doc) },
        })
      })
      .catch(() => {
        dispatch({
          type: ACTIONS.UPDATE_PAGE,
          payload: { page: ROOT_PAGE },
        })
      })
  }, [pageId])

  useEffect(() => {
    return database.pages
      
      .doc(pageId)
      .collection('links')
      .orderBy("order")
      .onSnapshot(snapshot => {
        dispatch({
          type: ACTIONS.SET_PAGE_LINKS,
          payload: { pageLinks: snapshot.docs.map(database.formatDoc) },
        })
      })
  }, [pageId, currentUser])

  function updatePageLinksOrder(reorderedLinks, updatedLink, newOrder) {
    // Start a batch operation
    const batch = firestore.batch();

    // If an updated link and new order are provided, integrate them into the reorderedLinks
    if (updatedLink && newOrder !== undefined) {
      // Find the index of the updated link
      const updateIndex = reorderedLinks.findIndex(link => link.id === updatedLink.id);
      // Remove the updated link from its original position
      if (updateIndex !== -1) {
        reorderedLinks.splice(updateIndex, 1);
      }
      // Insert the updated link at its new position
      reorderedLinks.splice(newOrder, 0, updatedLink);
    }

    // Ensure the order is sequential and starts from 0
    reorderedLinks = reorderedLinks.map((link, index) => ({ ...link, order: index }));

    // Update each link in the batch
    reorderedLinks.forEach((link) => {
      const linkRef = database.pages.doc(pageId).collection('links').doc(link.id);
      batch.update(linkRef, { order: link.order });
    });

    // Commit the batch
    batch.commit().then(() => {
      console.log('Updated order in the database');
    }).catch(error => {
      console.error('Error updating order in the database', error);
    });
  }

  return { ...state, reorderPageLinks: updatePageLinksOrder };
}
