import React, { useRef, useState, useEffect } from "react"
import { Form, Button, Card, Alert, Collapse, Row, Col } from "react-bootstrap"
import { RiSendPlaneFill, RiAccountCircleFill } from "react-icons/ri";
import { Link, useHistory } from "react-router-dom"
import { database } from "../../firebase"
import { useAuth } from "../../contexts/UserContext"

export default function Welcome() {
  const { signup } = useAuth()
  const emailRef = useRef()
  const passwordRef = useRef()
  const pageRef = useRef()

  const [error, setError] = useState("")
  const [openSignup, setOpen] = useState(false);

  const [loading, setLoading] = useState(false)
  const [nameLock, setNameLock] = useState(false)
  const [pageName, setPageName] = useState("")
  const [validPage, setValidPageName] = useState("reset")
  const history = useHistory()

  useEffect(() => {
    console.log("validPage changed ="+validPage)
    if(validPage){
      if(validPage === "reset"){
        document.getElementById("page-name-input").classList.remove("is-invalid")
        document.getElementById("page-name-input").classList.remove("is-valid")
        document.getElementById("page-name-button").classList.remove("disabled")
        setNameLock(false)
      } else {
        document.getElementById("page-name-input").classList.remove("is-invalid")
        document.getElementById("page-name-input").classList.add("is-valid")
        document.getElementById("page-name-button").classList.add("disabled")
        setNameLock(true)
      }
    } else {
      document.getElementById("page-name-input").classList.remove("is-valid")
      document.getElementById("page-name-input").classList.add("is-invalid")
      document.getElementById("page-name-button").classList.remove("disabled")
      setNameLock(false)
    }
  }, [validPage])  

  async function checkPage(e) {
    e.preventDefault()
    setLoading(true)

    var pageResult = null
    database.pages
    .where("name", "==", pageRef.current.value)
    .get()
    .then(doc => {
      doc.forEach((doc) => {
            if (doc.exists){
              setError(pageRef.current.value+" is already taken")
              setOpen(false)
              setValidPageName(false)
              pageResult = doc.data()
            }
        })
        if(!pageResult){
          setError("")
          setValidPageName(true)
          setOpen(true)
        }
      })
    .catch((error) => {
      console.log(error)
      setError(error.message)
    })
    setLoading(false)
  }


  async function claimPage(e) {
    e.preventDefault()

    try {
      setError("")
      setLoading(true)
      await signup(emailRef.current.value, passwordRef.current.value).then((userCridential) => {
        database.pages.doc(userCridential.user.uid).set({
          name: pageRef.current.value,
          displayName: "@"+pageRef.current.value,
          createdAt: database.getCurrentTimestamp(),          
          prof: null,
          theme: "undefined",
          backgroundColor: "#ffffff",
          primaryColor: "#fac935",
          primaryColorInverse: "#000000",
          color: "#000000",
        }, { merge: true }).then((pageResults) => {
            console.log("Page created!"+pageResults)
            history.push("/")
        })
        .catch((error) => {
          setError(error.message)
        })
      }).catch((error) => {
        console.log(error)
        setError(error.message)
      })
    } catch {
      console.log(error)
      setError("Failed to create an account")
    }

    setLoading(false)
  }

  function changeName(e){
    e.preventDefault()    
    document.getElementById("sun-rings").classList.remove("rotate")
    setValidPageName("reset")
    setOpen(false)
    getStarted(e)
  }
  function getStarted(e) {
    e.preventDefault()
    pageRef.current.focus();
  }  
  function updatePageName(e){
    e.preventDefault()
    document.getElementById("sun-rings").classList.add("rotate")
    setPageName(e.target.value)
    setValidPageName("reset")
    setOpen(false)
  }

  return (
    <>
          <div className="welcome-container">
            <div className="header">
              <div className="inner-header">
              </div>
              <div className="page-demo-container container-fluid">
              <Row>
              <Col sm={12}  md={7}>
              <div className="sun">
                <div id="sun-rings" className="rings"><div></div><div></div><div></div><div></div></div>
              </div>
              <Card className="page-demo">
             
                <RiAccountCircleFill className="profile-holder" alt="you" />
                <Form.Control defaultValue={pageName} onClick={getStarted} className="plain-form mb-3 text-center" size="sm" type="text" placeholder="@your-name"/>
                <Button onClick={getStarted} variant="outline-primary">Get started</Button>
                <Button onClick={getStarted} variant="outline-primary">Choose your @name</Button>
                <Button onClick={getStarted} variant="outline-primary">Start building</Button>
                <Button onClick={getStarted} variant="outline-primary">Click already</Button>
                <h2>+</h2>
                </Card>
                </Col>
                <Col sm={12} md={5} className="align-self-center">
                  <div className="p-3 text-center">

                    <h2 className="text-center mb-4">Claim your page</h2>
                    {error && <Alert variant="danger">{error}</Alert>}
                    <Form onSubmit={checkPage} id="claim-form"  className="my-3">
                      <Form.Group id="text">
                        <Form.Control size="lg" disabled={nameLock} onChange={updatePageName} placeholder="duo.page/you" id="page-name-input" type="text" ref={pageRef} required />
                      </Form.Group>
                      <Button size="lg" id="page-name-button" disabled={loading} className="w-100" type="submit">
                        <RiSendPlaneFill />
                      </Button>
                    </Form>

                    <Collapse in={openSignup}>
                      <Form onSubmit={claimPage} id="signup-form">
                        <Link onClick={changeName}> Choose a different name</Link>
                        <div className="text-center my-3">
                          Page available! Sign up to get started
                        </div>
                        <Form.Group id="email">
                          <Form.Control size="lg" placeholder="you@youremail.com" type="email" ref={emailRef} required />
                        </Form.Group>
                        <Form.Group id="password">
                          <Form.Control size="lg" placeholder="password" type="password" ref={passwordRef} required />
                        </Form.Group>
                        <Button size="lg" disabled={loading} className="w-100" type="submit">
                          Sign Up
                        </Button>
                      </Form>
                    </Collapse>
                    <div className="w-100 text-center my-3">
                      Already have a page? <Link to="/login">Log In</Link>
                    </div>

                  </div>
                </Col>
                </Row>


                </div>
              <div><svg className="waves" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto"><defs><path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" /></defs><g className="parallax"><use href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" /><use href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" /><use href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" /><use href="#gentle-wave" x="48" y="7" fill="#fff" /></g></svg></div>
              {/* <a>more</a> */}
            </div>
          </div>
    </>
  )
}
